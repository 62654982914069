import { getPatientSessionKey } from "@recare/core/model/patients/encryption";
import { Auction, GetOntologyType } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { useIsSealdOnly } from "containers/SealdOnlyProvider";
import { Divider, ResponsiveLayout } from "ds/materials/layouts";
import { margin } from "ds/materials/metrics";
import { Body, Subheading, Title } from "ds/materials/typography";
import { useLoggedInAccount } from "reduxentities/selectors";
import {
  BooleanField,
  CategoryContainer,
  EncryptedField,
  OntologyField,
  StringField,
} from "./shared";

export function TransportOrganizedByInfo({
  title,
  transportOrganizer,
}: {
  title: string;
  transportOrganizer: string;
}) {
  return <StringField prefix={title} value={transportOrganizer} />;
}

const AccompanyingPersonPrint = ({
  auction,
  forCareprovider,
  translations,
}: {
  auction: Auction;
  forCareprovider?: boolean;
  translations: Translations;
}) => {
  const data = auction?.patient?.transport;
  if (!data) return null;

  const { accompanying_person, require_accompanying_person } = data;
  if (!forCareprovider && !accompanying_person)
    return (
      <BooleanField
        value={require_accompanying_person}
        label={translations.patient.transport.accompanyingPersonNecessary}
      />
    );
  if (accompanying_person?.decrypted)
    return (
      <Body>{`${translations.patient.transport.accompanyingPersonNecessary}: ${accompanying_person?.decrypted}`}</Body>
    );
  return null;
};

function AccompanyingPersonInfo({
  auction,
  translations,
}: {
  auction: Auction;
  translations: Translations;
}) {
  const account = useLoggedInAccount();
  const isSealdOnly = useIsSealdOnly().isSealdOnly({
    oldSession: auction.patient?.session_key_context?.has_session_keys,
    newSealdSession: auction.patient?.seald_encryption_context?.seald_id,
  });
  const withEncryptionAccess = isSealdOnly
    ? auction.patient?.seald_encryption_context != null
    : getPatientSessionKey(account, auction.patient) != null;

  const data = auction.patient.transport;
  if (!data) return null;

  const { accompanying_person, require_accompanying_person } = data;
  if (!require_accompanying_person) return null;

  return (
    <EncryptedField
      value={accompanying_person}
      prefix={translations.patient.transport.accompanyingPersonNecessary}
      isSealdOnly={isSealdOnly}
      withEncryptionAccess={withEncryptionAccess}
      longContext
    />
  );
}

export const AccompanyingPerson = ({
  auction,
  forCareprovider,
  forPrint,
  translations,
}: {
  auction: Auction;
  forCareprovider?: boolean;
  forPrint?: boolean;
  translations: Translations;
}) => {
  if (forPrint) {
    return (
      <AccompanyingPersonPrint
        forCareprovider={forCareprovider}
        translations={translations}
        auction={auction}
      />
    );
  }
  return (
    <AccompanyingPersonInfo translations={translations} auction={auction} />
  );
};

export default function TransportCard({
  auction,
  forCareprovider = false,
  forPrint,
  getOntology,
}: {
  auction: Auction;
  forCareprovider?: boolean;
  forPrint?: boolean;
  getOntology: GetOntologyType;
}) {
  const data = auction.patient.transport;
  const translations = useTranslations();

  if (!data) return null;

  const {
    daily_transport_needed,
    require_accompanying_person,
    transport_organiser,
    transport_type,
  } = data;

  if (
    !transport_type &&
    !transport_organiser &&
    !daily_transport_needed &&
    !require_accompanying_person
  ) {
    return null;
  }

  if (forCareprovider) {
    return (
      <>
        <Divider />

        <Subheading bold>
          {translations.patient.transport.typeOfTransport}
        </Subheading>

        <ResponsiveLayout>
          <CategoryContainer>
            <OntologyField
              getOntology={getOntology}
              type="transportType"
              value={transport_type}
              verticalLayout
            />
          </CategoryContainer>

          <CategoryContainer>
            <BooleanField
              value={daily_transport_needed}
              label={translations.patient.transport.dailyTransportNeeded}
            />
          </CategoryContainer>
        </ResponsiveLayout>

        <ResponsiveLayout>
          <CategoryContainer>
            <AccompanyingPerson
              auction={auction}
              translations={translations}
              forPrint={forPrint}
              forCareprovider
            />
          </CategoryContainer>

          <CategoryContainer>
            {transport_organiser && (
              <TransportOrganizedByInfo
                transportOrganizer={transport_organiser}
                title={translations.patient.transport.organizedBy.title}
              />
            )}
          </CategoryContainer>
        </ResponsiveLayout>
      </>
    );
  }

  return (
    <>
      {forPrint ? (
        <Subheading bold>{translations.patient.transport.stepTitle}</Subheading>
      ) : (
        <Title>{translations.patient.transport.stepTitle}</Title>
      )}
      <OntologyField
        getOntology={getOntology}
        type="transportType"
        value={transport_type}
        verticalLayout
      />
      <BooleanField
        value={daily_transport_needed}
        label={translations.patient.transport.dailyTransportNeeded}
      />
      <AccompanyingPerson
        auction={auction}
        translations={translations}
        forPrint={forPrint}
      />
      {transport_organiser && (
        <TransportOrganizedByInfo
          transportOrganizer={transport_organiser}
          title={translations.patient.transport.organizedBy.title}
        />
      )}
      <Divider margin={margin(2, 0, 0, 2)} />
    </>
  );
}
