import {
  ASSESSMENT_SLUG_MEDICAL_SUPPLIES_INFO,
  DELIVERY_ADDRESS_CLINIC_ADDRESS,
  DELIVERY_ADDRESS_NEW_ADDRESS,
  DELIVERY_ADDRESS_PATIENT_ADDRESS,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
} from "@recare/core/consts";
import { formatLocation } from "@recare/core/model/utils/location";
import { useGetOntology } from "@recare/core/model/utils/ontologies/hooks";
import {
  Auction,
  GetOntologyType,
  MedicalProductType,
  MedicalSupplies,
  SearchType,
} from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { VerticalLayout } from "ds/materials/layouts";
import { PatientInfoSlugContext, usePatientInfoContext } from ".";
import { BooleanField, Categories, Category, StringField } from "./shared";

export const formatProductString = (
  product: MedicalProductType,
  translations: Translations,
) =>
  `${product?.name || ""} #${product.id} ${translations.general.group} ${
    product?.product_group || ""
  } ${product?.subgroup || ""}`;

const Products = ({
  translations,
  value,
}: {
  translations: Translations;
  value: Auction | null | undefined;
}) => {
  const getOntology = useGetOntology();
  const contents = [];

  if (
    value?.medical_supplies?.products &&
    value?.medical_supplies?.products?.length > 0
  ) {
    value.medical_supplies.products.forEach((product: MedicalProductType) => {
      if (!product?.id) return;
      const stringValue = formatProductString(product, translations);
      contents.push(<StringField key={product.id} value={stringValue} />);
    });
  }
  if (value?.medical_supplies?.additional_information)
    contents.push(
      <StringField
        prefix={translations.patient.additionalInformation}
        key="additional_information"
        value={value?.medical_supplies?.additional_information}
      />,
    );

  if (value?.specializations) {
    contents.push(
      <StringField
        prefix={translations.careproviderApp.notifications.specialization}
        key="home_care_specialization"
        value={getOntology({
          type: "specializations",
          key: value.specializations[0],
        })}
      />,
    );
  }

  if (contents?.length === 0) return null;

  return contents?.length ? (
    <Category title={translations.assessments.medicalSupplies.medicalSupplies}>
      <VerticalLayout>{contents}</VerticalLayout>
    </Category>
  ) : null;
};

export const locationValue = (
  auction: Auction | null | undefined,
  translations: Translations,
) => {
  const formatLocations = (location: any) => {
    return location
      ? formatLocation({
          location,
          translations,
          showFloor: true,
        })
      : "";
  };
  switch (auction?.medical_supplies?.delivery_address_type) {
    case DELIVERY_ADDRESS_PATIENT_ADDRESS: {
      return {
        deliveryAddressString: formatLocations(
          auction.profile?.search_location,
        ),
        deliveryAddressPrefix: translations.address.patientAddress,
      };
    }
    case DELIVERY_ADDRESS_CLINIC_ADDRESS: {
      return {
        deliveryAddressString: formatLocations(
          auction.patient.careseeker?.address,
        ),
        deliveryAddressPrefix: translations.address.clinicAddress,
      };
    }
    case DELIVERY_ADDRESS_NEW_ADDRESS: {
      return {
        deliveryAddressString: formatLocations(
          auction.medical_supplies.delivery_address,
        ),
        deliveryAddressPrefix: translations.address.anotherAddress,
      };
    }
    default:
      return { deliveryAddressString: "", deliveryAddressPrefix: "" };
  }
};

const DeliveryDetails = ({
  translations,
  value,
}: {
  translations: Translations;
  value: Auction | null | undefined;
}) => {
  const { deliveryAddressPrefix, deliveryAddressString } = locationValue(
    value,
    translations,
  );
  return (
    <Category title={translations.assessments.medicalSupplies.deliveryDetails}>
      <StringField
        prefix={deliveryAddressPrefix}
        value={deliveryAddressString}
      />
    </Category>
  );
};

const Prescription = ({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: MedicalSupplies | null | undefined;
  translations: Translations;
  value: MedicalSupplies | null | undefined;
  withDiff: boolean;
}) => {
  return (
    <Category title={translations.assessments.medicalSupplies.prescription}>
      {value?.prescription_issued_description ? (
        <StringField
          prefix={translations.assessments.medicalSupplies.prescriptionIssued}
          value={value?.prescription_issued_description}
          oldValue={oldValue?.prescription_issued_description}
          withDiff={withDiff}
        />
      ) : (
        <BooleanField
          label={translations.assessments.medicalSupplies.prescriptionIssued}
          value={value?.prescription_issued}
          oldValue={oldValue?.prescription_issued}
          withDiff={withDiff}
        />
      )}
    </Category>
  );
};

const componentMapping: {
  Component: (props: ToType) => JSX.Element | null;
  key: string;
}[] = [
  {
    Component: Products,
    key: "products",
  },

  {
    Component: Prescription,
    key: "prescription",
  },
  {
    Component: DeliveryDetails,
    key: "delivery_details",
  },
];

export const medicalSuppliesFilters: {
  [key: string]: { exists: (auction: Auction) => boolean; valueGetter: ToType };
} = {
  products: {
    exists: (auction: Auction) =>
      auction?.medical_supplies?.products != null ||
      auction?.medical_supplies?.additional_information != null ||
      auction?.specializations != null,
    valueGetter: (auction: Auction) => auction,
  },
  prescription: {
    exists: (auction: Auction) => {
      return auction?.medical_supplies?.prescription_issued === true;
    },
    valueGetter: (auction: Auction) => auction?.medical_supplies,
  },
  delivery_details: {
    exists: (auction: Auction) => auction != null,
    valueGetter: (auction: Auction) => auction,
  },
};

const getCategories = (
  auction: Auction,
  oldAuction: Auction | null | undefined,
) => {
  return componentMapping.map((c) => ({
    Component: c.Component,
    key: c.key,
    exists:
      medicalSuppliesFilters[c.key].exists(
        oldAuction || ({ id: -1 } as Auction),
      ) || medicalSuppliesFilters[c.key].exists(auction),
    valueGetter: medicalSuppliesFilters[c.key].valueGetter,
  }));
};

export default function TransportSectionCard({
  auction,
  getOntology,
  oldAuction,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
}) {
  const translations = useTranslations();
  const categories = getCategories(auction, oldAuction);
  const { Card } = usePatientInfoContext();
  if (
    !(
      [SEARCH_TYPE_MEDICAL_SUPPLIES, SEARCH_TYPE_HOME_CARE] as SearchType[]
    ).includes(auction.search_type)
  )
    return null;

  return (
    <Card title={translations.assessments.medicalSupplies.medicalSupplies}>
      <PatientInfoSlugContext.Provider
        value={{
          patientId: auction.patient.id,
          auctionId: auction.id,
          assessmentSlug: ASSESSMENT_SLUG_MEDICAL_SUPPLIES_INFO,
        }}
      >
        <Categories
          auction={auction}
          oldAuction={oldAuction}
          translations={translations}
          getOntology={getOntology}
          categories={categories}
        />
      </PatientInfoSlugContext.Provider>
    </Card>
  );
}
