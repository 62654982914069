import { CARD_BORDER_LIGHT } from "ds/materials/colors";

export const MAP_PLACEHOLDER_COLOR = CARD_BORDER_LIGHT;

export default function Placeholder({ height }: { height: string }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height,
        position: "relative",
        backgroundColor: MAP_PLACEHOLDER_COLOR,
      }}
    />
  );
}
