import {
  APP_CAREPROVIDER,
  ASSESSMENT_SLUG_INFO,
  ASSESSMENT_SLUG_MOBILE_SHORT,
  CARESEEKER_TYPE_PROVIDER_SEARCH,
  LIVING_SITUATION_OTHER,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_REHABILITATION,
} from "@recare/core/consts";
import { descriptiveWhitelist } from "@recare/core/model/auctions";
import {
  getAge,
  getCareLevelValue,
  getInterval,
} from "@recare/core/model/patients";
import { getAgeFromDate } from "@recare/core/model/utils/dates";
import {
  Auction,
  CarePreferences as CarePreferencesType,
  Insurance as CostPayerType,
  Financing as FinancingType,
  GeneralPractitioner as GeneralPractitionerType,
  GetOntologyType,
  PatientCommunication as PatientCommunicationType,
  PatientProfile,
  PatientProfile as PatientProfileType,
  Patient as PatientType,
  Payers as PayersType,
  SearchType,
} from "@recare/core/types";
import { getPredicamentValue } from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { useEnvContext } from "context/EnvContext";
import { TEXT_DARK_SECONDARY } from "ds/materials/colors";
import { HorizontalLayout } from "ds/materials/layouts";
import { dp } from "ds/materials/metrics";
import { Body } from "ds/materials/typography";
import {
  carelevelWhitelist,
  grantsWhitelist,
  healthInsuranceWhitelist,
  insuranceWhitelist,
  reimbursment_for_help_at_homeWhitelist,
  reimbursment_securedWhitelist,
  reliefServiceBoolWhitelist,
  shortTermCareWhitelist,
  socialHelpRecipientBoolWhitelist,
} from "dsl/organisms/Infos/PatientInformation/modelDefinition";
import isEmpty from "lodash/isEmpty";
import { useCallback } from "react";
import styled from "styled-components";
import GrantStatusField from "../GrantStatusField";
import ReliefServiceGrant from "../ReliefServiceGrant";
import ShortTermCareGrant from "../ShortTermCareGrant";
import { PatientInfoSlugContext, usePatientInfoContext } from "./index";
import {
  BodyWrapper,
  BooleanField,
  Categories,
  Category,
  CategoryDescription,
  EmptiableCategory,
  EmptiableField,
  EncryptedField,
  LocationContentSection,
  OntologyField,
  StringField,
  StringValue,
  ValueGetter,
  inputCollectionIsNotEmpty,
  isWhitelisted,
} from "./shared";

export const BoldPrefix = styled.b`
  margin-right: ${dp(-12)};
`;

function GeneralInformation({
  getOntology,
  isSealdOnly,
  oldValue,
  translations,
  value,
  withDiff,
  withEncryptionAccess,
}: {
  getOntology: GetOntologyType;
  isSealdOnly: boolean;
  oldValue: Auction | null | undefined;
  translations: Translations;
  value: Auction | null | undefined;
  withDiff: boolean;
  withEncryptionAccess: boolean;
}) {
  const birthDate = value?.patient?.profile?.birth_date?.decrypted;
  const isProviderSearchPatient =
    value?.patient?.careseeker?.type === CARESEEKER_TYPE_PROVIDER_SEARCH;
  return (
    <Category
      category="general_information"
      title={translations.patient.patientInformation.generalInformation}
    >
      <EncryptedField
        value={value?.patient?.profile?.first_name}
        prefix={translations.patient.firstName}
        bold
        isSealdOnly={isSealdOnly}
        withEncryptionAccess={withEncryptionAccess}
      />
      <EncryptedField
        value={value?.patient?.profile?.last_name}
        prefix={translations.patient.lastName}
        bold
        isSealdOnly={isSealdOnly}
        withEncryptionAccess={withEncryptionAccess}
      />
      <OntologyField
        getOntology={getOntology}
        type="gender"
        value={value?.patient?.profile?.gender}
        oldValue={oldValue?.patient?.profile?.gender}
        withDiff={withDiff}
        wrap={(content) => (
          <BodyWrapper>
            <BoldPrefix>{`${translations.patient.gender}${translations.general.colon} `}</BoldPrefix>
            {content}
          </BodyWrapper>
        )}
      />
      <EncryptedField
        value={value?.patient?.profile?.birth_date}
        prefix={translations.patient.dateOfBirth}
        bold
        withDateString
        isSealdOnly={isSealdOnly}
        withEncryptionAccess={withEncryptionAccess}
      />
      <StringField
        value={
          birthDate
            ? getAgeFromDate(birthDate).toString()
            : (
                getAge(
                  value?.patient?.profile?.age_interval,
                  value?.search_type,
                ) || ""
              ).toString()
        }
        oldValue={
          birthDate
            ? getAgeFromDate(birthDate).toString()
            : (
                getAge(
                  oldValue?.patient?.profile?.age_interval,
                  oldValue?.search_type,
                ) || ""
              ).toString()
        }
        withDiff={withDiff}
        wrap={(content) => (
          <BodyWrapper>
            <BoldPrefix>{`${translations.patient.age}${translations.general.colon} `}</BoldPrefix>
            {content}
          </BodyWrapper>
        )}
      />
      {withEncryptionAccess ? (
        <EncryptedField
          value={value?.patient?.profile?.height}
          hideEmpty
          prefix={translations.patient.formGeneration.height}
          bold
          isSealdOnly={isSealdOnly}
          withEncryptionAccess
          suffix="cm"
        />
      ) : (
        <StringField
          value={getInterval(
            value?.patient,
            value?.search_type,
            isProviderSearchPatient,
            "height_interval",
            translations,
          )}
          oldValue={getInterval(
            oldValue?.patient,
            oldValue?.search_type,
            isProviderSearchPatient,
            "height_interval",
            translations,
          )}
          withDiff={withDiff}
          // @ts-ignore
          wrap={(content: { props: { value: string } }) =>
            content?.props?.value ? (
              <BodyWrapper>
                <HorizontalLayout>
                  <BoldPrefix>{`${translations.patient.formGeneration.height}${translations.general.colon} `}</BoldPrefix>
                  <Body>{`${content.props.value}cm`}</Body>
                </HorizontalLayout>
              </BodyWrapper>
            ) : null
          }
        />
      )}
      {withEncryptionAccess ? (
        <EncryptedField
          value={value?.patient?.profile?.weight}
          prefix={translations.patient.formGeneration.weight}
          bold
          isSealdOnly={isSealdOnly}
          hideEmpty
          withEncryptionAccess
          suffix="kg"
        />
      ) : (
        <StringField
          value={getInterval(
            value?.patient,
            value?.search_type,
            isProviderSearchPatient,
            "weight_interval",
            translations,
          )}
          oldValue={getInterval(
            oldValue?.patient,
            oldValue?.search_type,
            isProviderSearchPatient,
            "weight_interval",
            translations,
          )}
          withDiff={withDiff}
          // @ts-ignore
          wrap={(content: { props: { value: string } }) =>
            content?.props?.value ? (
              <BodyWrapper>
                <HorizontalLayout>
                  <BoldPrefix>{`${translations.patient.formGeneration.weight}${translations.general.colon} `}</BoldPrefix>
                  <Body>{`${content.props.value}kg`}</Body>
                </HorizontalLayout>
              </BodyWrapper>
            ) : null
          }
        />
      )}
      <LocationContentSection
        location={value?.profile?.search_location}
        oldLocation={oldValue?.profile?.search_location}
        withDiff={withDiff}
        translations={translations}
      />
    </Category>
  );
}

function HealthInsurance({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: PatientType | null | undefined;
  translations: Translations;
  value: PatientType | null | undefined;
  withDiff: boolean;
}) {
  const financing = value?.profile?.financing;
  const oldFinancing = oldValue?.profile?.financing;

  return (
    <Category title={translations.patient.patientInformation.insurance}>
      <EmptiableField
        title={translations.patient.hasHealthInsurance}
        value={getPredicamentValue({
          translations,
          value: financing?.patient_has_health_insurance_state,
        })}
        oldValue={getPredicamentValue({
          translations,
          value: oldFinancing?.patient_has_health_insurance_state,
        })}
        withDiff={withDiff}
        noEmptyValue
      />
    </Category>
  );
}

function Insurance({
  isSealdOnly,
  oldValue,
  translations,
  value,
  withDiff,
  withEncryptionAccess,
}: {
  isSealdOnly: boolean;
  oldValue: PatientType | null | undefined;
  translations: Translations;
  value: PatientType | null | undefined;
  withDiff: boolean;
  withEncryptionAccess: boolean;
}) {
  const financing = value?.profile?.financing;
  const oldFinancing = oldValue?.profile?.financing;

  return (
    <Category title={translations.patient.patientInformation.insurance}>
      <StringField
        prefix={translations.patient.insuranceProvider}
        value={financing?.insurance?.name}
        oldValue={oldFinancing?.insurance?.name}
        withDiff={withDiff}
      />
      <EncryptedField
        hideEmpty
        value={financing?.insurance_number}
        prefix={translations.patient.insuranceNumber}
        isSealdOnly={isSealdOnly}
        withEncryptionAccess={withEncryptionAccess}
      />
    </Category>
  );
}

function Carelevel({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: FinancingType | null | undefined;
  translations: Translations;
  value: FinancingType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category title={translations.patient.carelevel}>
      <StringField
        value={getCareLevelValue(value?.carelevel, translations)?.label}
        oldValue={getCareLevelValue(oldValue?.carelevel, translations)?.label}
        withDiff={withDiff}
      />
      <BooleanField
        value={!!value?.carelevel?.higher_level}
        oldValue={!!oldValue?.carelevel?.higher_level}
        label={translations.patient.hasAppliedforHigherCarelevel}
        withDiff={withDiff}
      />
      <BooleanField
        value={!!value?.carelevel?.expedited_request}
        oldValue={!!oldValue?.carelevel?.expedited_request}
        label={translations.patient.expeditedRequest}
        withDiff={withDiff}
      />

      <BooleanField
        value={!!value?.carelevel?.has_applied}
        oldValue={!!oldValue?.carelevel?.has_applied}
        label={translations.patient.hasAppliedforCarelevel}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.public_care_insurance_status}
        oldValue={oldValue?.public_care_insurance_status}
        label={translations.patient.publicCareInsuranceStatus}
        withDiff={withDiff}
      />
    </Category>
  );
}

function Payout({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: number | null | undefined;
  translations: Translations;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category title={translations.patient.patientInformation.payout}>
      <CategoryDescription
        value={getOntology({ type: "grantsType", key: value }) || null}
        oldValue={getOntology({ type: "grantsType", key: oldValue }) || null}
        withDiff={withDiff}
      />
    </Category>
  );
}

function GeneralPractitioner({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: GeneralPractitionerType | null | undefined;
  translations: Translations;
  value: GeneralPractitionerType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      title={translations.patient.patientInformation.generalPractitionerTitle}
    >
      <StringField
        value={value?.name}
        oldValue={oldValue?.name}
        withDiff={withDiff}
      />
      <StringField
        value={value?.contact_details}
        oldValue={oldValue?.contact_details}
        withDiff={withDiff}
      />
    </Category>
  );
}

const isLivingOther = (v: PatientProfileType | null | undefined) =>
  v && v.living_situation === LIVING_SITUATION_OTHER;

function LivingSituation({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: { profile?: PatientProfile; search_type: SearchType };
  translations: Translations;
  value: { profile?: PatientProfile; search_type: SearchType };
  withDiff: boolean;
}) {
  const isReha = value.search_type === SEARCH_TYPE_REHABILITATION;
  const hasEmptyField = isReha
    ? value?.profile?.living_situation == null ||
      value?.profile?.lift_available_state == null ||
      value?.profile?.barrier_free == null ||
      value?.profile?.unsafe_current_domestic_situation_state == 0
    : value?.profile?.living_situation == null;

  const getLivingTranslation = useCallback(
    (v: PatientProfile | null | undefined) =>
      (v?.living_situation &&
        v?.living_situation > 0 &&
        getOntology({ type: "livingSituation", key: v.living_situation })) ||
      null,
    [getOntology],
  );
  const livingOther = isLivingOther(value?.profile);

  return (
    <EmptiableCategory
      title={translations.patient.patientInformation.livingSituation}
      hasEmptyField={hasEmptyField}
      category="living_situation"
    >
      <StringField
        prefix={translations.patient.patientInformation.patientIsLiving}
        value={getLivingTranslation(value?.profile)}
        oldValue={getLivingTranslation(oldValue?.profile)}
        withDiff={withDiff}
      />
      {livingOther && !hasEmptyField && (
        <EmptiableField
          value={value?.profile?.living_situation_other}
          oldValue={oldValue?.profile?.living_situation_other}
          withDiff={withDiff}
        />
      )}
      <StringField
        value={getPredicamentValue({
          label: translations.patient.patientInformation.liftAvailable,
          value: value?.profile?.lift_available_state,
          translations,
        })}
        oldValue={getPredicamentValue({
          label: translations.patient.patientInformation.liftAvailable,
          value: oldValue?.profile?.lift_available_state,
          translations,
        })}
        withDiff={withDiff}
      />
      <StringField
        value={value?.profile?.lift_available_description}
        oldValue={oldValue?.profile?.lift_available_description}
        withDiff={withDiff}
      />
      <StringField
        value={getPredicamentValue({
          label: translations.patient.titleBarrierFree,
          value: value?.profile?.barrier_free,
          translations,
        })}
        oldValue={getPredicamentValue({
          label: translations.patient.titleBarrierFree,
          value: oldValue?.profile?.barrier_free,
          translations,
        })}
        withDiff={withDiff}
      />
      <StringField
        value={value?.profile?.barrier_free_description}
        oldValue={oldValue?.profile?.barrier_free_description}
        withDiff={withDiff}
      />
      <StringField
        value={getPredicamentValue({
          label:
            translations.patient.patientInformation.currentDomesticSituation,
          value: value?.profile?.unsafe_current_domestic_situation_state,
          translations,
        })}
        oldValue={getPredicamentValue({
          label:
            translations.patient.patientInformation.currentDomesticSituation,
          value: oldValue?.profile?.unsafe_current_domestic_situation_state,
          translations,
        })}
        withDiff={withDiff}
      />
      <StringField
        value={value?.profile?.unsafe_current_domestic_situation}
        oldValue={oldValue?.profile?.unsafe_current_domestic_situation}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

type GrantElement = {
  hasNotApplied: boolean;
  label: string;
  oldValue: ToType;
  show: boolean;
  value: ToType;
};

function Grants({
  auction,
  getOntology,
  oldAuction,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  oldAuction: Auction | null | undefined;
  oldValue: FinancingType | null | undefined;
  translations: Translations;
  value: FinancingType | null | undefined;
  withDiff: boolean;
}) {
  if (!isWhitelisted(grantsWhitelist, auction, oldAuction)) {
    return null;
  }

  const grantElements: GrantElement[] = [
    {
      hasNotApplied: true,
      label: translations.patient.reimbursmentForHelpAtHome,
      value: value?.reimbursment_for_help_at_home,
      oldValue: oldValue?.reimbursment_for_help_at_home,
      show: isWhitelisted(
        reimbursment_for_help_at_homeWhitelist,
        auction,
        oldAuction,
      ),
    },
    {
      hasNotApplied: true,
      label: translations.patient.reimbursmentSecured,
      value: value?.reimbursment_secured,
      oldValue: oldValue?.reimbursment_secured,
      show: isWhitelisted(reimbursment_securedWhitelist, auction, oldAuction),
    },
    {
      hasNotApplied: true,
      label: translations.patient.preventativeCare,
      value: value?.preventative_care,
      oldValue: oldValue?.preventative_care,
      show: true,
    },
    {
      hasNotApplied: false,
      label: translations.patient.socialHelpRecipient,
      value: value?.social_help_recipient_bool,
      oldValue: oldValue?.social_help_recipient_bool,
      show: isWhitelisted(
        socialHelpRecipientBoolWhitelist,
        auction,
        oldAuction,
      ),
    },
    {
      hasNotApplied: false,
      label: translations.patient.patientInformation.acceptsPrivatePayment,
      value: value?.private_payment_bool,
      oldValue: oldValue?.private_payment_bool,
      show: true,
    },
  ];

  const shortTermGrant: GrantElement = {
    hasNotApplied: false,
    label: translations.patient.shortTermCareGrant,
    value: value?.short_term_care,
    oldValue: oldValue?.short_term_care,
    show: isWhitelisted(shortTermCareWhitelist, auction, oldAuction),
  };

  const reliefServices: GrantElement = {
    hasNotApplied: false,
    label: translations.patient.reliefServices,
    value: value?.relief_services,
    oldValue: oldValue?.relief_services,
    show: isWhitelisted(reliefServiceBoolWhitelist, auction, oldAuction),
  };

  const uncheckedGrants: GrantElement[] = [
    ...grantElements,
    shortTermGrant,
    reliefServices,
  ].filter(
    (element: GrantElement) =>
      !element.value &&
      typeof element.label === "string" &&
      element.label.length > 0 &&
      element.show,
  );

  return (
    <Category category="grants" title={translations.patient.grants}>
      {grantElements.map((element: GrantElement) =>
        element.show ? (
          <GrantStatusField
            getOntology={getOntology}
            key={`grant-key-${element.label}`}
            hasNotApplied={element.hasNotApplied}
            label={element.label}
            value={element.value}
            oldValue={element.oldValue}
            withDiff={withDiff}
          />
        ) : null,
      )}

      {shortTermGrant.show && (
        <ShortTermCareGrant
          getOntology={getOntology}
          value={shortTermGrant.value}
          oldValue={shortTermGrant.oldValue}
          withDiff={withDiff}
        />
      )}
      {reliefServices.show && (
        <ReliefServiceGrant
          value={reliefServices.value}
          oldValue={reliefServices.oldValue}
          withDiff={withDiff}
        />
      )}
      <EmptiableField
        title={translations.patient.patientInformation.grantsMoreInformation}
        value={value?.more_information}
        oldValue={oldValue?.more_information}
        withDiff={withDiff}
        noEmptyValue
      />
      {uncheckedGrants.length > 0 && !withDiff && (
        <StringValue
          style={{ color: TEXT_DARK_SECONDARY }}
          value={`${
            translations.ontologies.grantStatus.unselectedItems
          }${uncheckedGrants.map(({ label }) => ` ${label}`)}`}
        />
      )}
    </Category>
  );
}

function getDescriptionForInsurance(
  value?: CostPayerType | null | undefined,
): string | null | undefined {
  if (
    value === null ||
    typeof value === "undefined" ||
    typeof value.name !== "string"
  ) {
    return null;
  }
  return value.name;
}

function Payers({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: PayersType | null | undefined;
  translations: Translations;
  value: PayersType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      category="payers"
      title={translations.patient.patientInformation.payers}
    >
      <EmptiableField
        title={translations.patient.patientInformation.payersInsurance}
        value={getDescriptionForInsurance(value?.insurance)}
        oldValue={getDescriptionForInsurance(oldValue?.insurance)}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.patientInformation.civilServantsAid}
        value={value?.civil_servants_aid}
        oldValue={oldValue?.civil_servants_aid}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.patientInformation.publicAccidentInsurance}
        value={value?.public_accident_insurance}
        oldValue={oldValue?.public_accident_insurance}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.patientInformation.publicPension}
        value={getDescriptionForInsurance(value?.public_pension)}
        oldValue={getDescriptionForInsurance(oldValue?.public_pension)}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.patientInformation.socialServiceDepartment}
        value={value?.social_service_department}
        oldValue={oldValue?.social_service_department}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.patientInformation.selfPayer}
        value={value?.self_payer}
        oldValue={oldValue?.self_payer}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.patientInformation.payersOther}
        value={value?.other_payer}
        oldValue={oldValue?.other_payer}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.patientInformation.payersMoreInformations}
        value={value?.further_information}
        oldValue={oldValue?.further_information}
        withDiff={withDiff}
      />
      <EmptiableField
        value={getPredicamentValue({
          label: translations.patient.patientInformation.supplementaryInsurance,
          value: value?.supplementary_insurance_state,
          translations,
        })}
        oldValue={getPredicamentValue({
          label: translations.patient.patientInformation.supplementaryInsurance,
          value: oldValue?.supplementary_insurance_state,
          translations,
        })}
        withDiff={withDiff}
        noEmptyValue
      />
      <EmptiableField
        value={value?.supplementary_insurance}
        oldValue={oldValue?.supplementary_insurance}
        withDiff={withDiff}
      />
      <EmptiableField
        value={getPredicamentValue({
          label: translations.patient.patientInformation.supplementaryPayment,
          value: value?.supplementary_payment_state,
          translations,
        })}
        oldValue={getPredicamentValue({
          label: translations.patient.patientInformation.supplementaryPayment,
          value: oldValue?.supplementary_payment_state,
          translations,
        })}
        withDiff={withDiff}
        noEmptyValue
      />
      <EmptiableField
        value={value?.supplementary_payment}
        oldValue={oldValue?.supplementary_payment}
        withDiff={withDiff}
      />
      <EmptiableField
        title={
          translations.patient.patientInformation.freeFromSupplementaryPayment
        }
        value={getPredicamentValue({
          translations,
          value: value?.free_from_supplementary_payment_state,
        })}
        oldValue={getPredicamentValue({
          translations,
          value: oldValue?.free_from_supplementary_payment_state,
        })}
        withDiff={withDiff}
        noEmptyValue
      />
    </Category>
  );
}

function Communication({
  isSealdOnly,
  oldValue,
  translations,
  value,
  withDiff,
  withEncryptionAccess,
}: {
  isSealdOnly: boolean;
  oldValue: PatientCommunicationType | null | undefined;
  translations: Translations;
  value: PatientCommunicationType | null | undefined;
  withDiff: boolean;
  withEncryptionAccess: boolean;
}) {
  return (
    <Category
      category="communication"
      title={translations.patient.patientInformation.decisionsLanguages}
    >
      {value?.patient_is_contact_description ? (
        <EncryptedField
          value={value.patient_is_contact_description}
          prefix={translations.patient.patientInformation.contactIsPatient}
          bold
          verticalLayout
          isSealdOnly={isSealdOnly}
          withEncryptionAccess={withEncryptionAccess}
        />
      ) : (
        <BooleanField
          value={value?.patient_is_contact}
          oldValue={oldValue?.patient_is_contact}
          label={translations.patient.patientInformation.contactIsPatient}
          withDiff={withDiff}
        />
      )}
      {value?.relatives_description ? (
        <EncryptedField
          value={value.relatives_description}
          prefix={translations.patient.patientInformation.relativesAvailable}
          bold
          verticalLayout
          isSealdOnly={isSealdOnly}
          withEncryptionAccess={withEncryptionAccess}
        />
      ) : (
        <BooleanField
          value={value?.relatives_available}
          oldValue={oldValue?.relatives_available}
          label={translations.patient.patientInformation.relativesAvailable}
          withDiff={withDiff}
        />
      )}

      {value?.guardian_contact_information ? (
        <EncryptedField
          value={value?.guardian_contact_information}
          prefix={translations.patient.patientInformation.hasGuardian}
          verticalLayout
          bold
          isSealdOnly={isSealdOnly}
          withEncryptionAccess={withEncryptionAccess}
        />
      ) : (
        <EmptiableField
          title={translations.patient.patientInformation.hasGuardian}
          value={value?.has_guardian ? value.guardian_description : null}
          oldValue={
            oldValue?.has_guardian ? oldValue.guardian_description : null
          }
          withDiff={withDiff}
        />
      )}
      <BooleanField
        value={value?.guardian_requested}
        oldValue={oldValue?.guardian_requested}
        label={translations.patient.patientInformation.guardianRequested}
        withDiff={withDiff}
      />
      <EmptiableField
        title={
          translations.patient.patientInformation
            .patientCannotCommunicateInGerman.label
        }
        value={
          value?.patient_cannot_communicate_in_german
            ? value.patient_language
            : null
        }
        oldValue={
          oldValue?.patient_cannot_communicate_in_german
            ? oldValue.patient_language
            : null
        }
        withDiff={withDiff}
        bold
      />
      <EmptiableField
        title={
          translations.patient.patientInformation.patientUsesSignInLanguage
            .label
        }
        value={
          value?.patient_uses_sign_language
            ? value.patient_uses_sign_language
            : null
        }
        oldValue={
          oldValue?.patient_uses_sign_language
            ? oldValue.patient_uses_sign_language
            : null
        }
        withDiff={withDiff}
        bold
      />
    </Category>
  );
}

function Preferences({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: CarePreferencesType | null | undefined;
  translations: Translations;
  value: CarePreferencesType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      category="preferences"
      title={translations.patient.patientInformation.specialRequirements}
    >
      <OntologyField
        getOntology={getOntology}
        value={value?.room_type}
        oldValue={oldValue?.room_type}
        type="roomType"
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.barrier_free_room}
        oldValue={oldValue?.barrier_free_room}
        label={translations.patient.barrierFreeRoom}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.accompanying_person}
        oldValue={oldValue?.accompanying_person}
        label={translations.patient.accompanyingPerson}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.specialist_doctor}
        oldValue={oldValue?.specialist_doctor}
        label={translations.patient.specialistDoctor}
        withDiff={withDiff}
      />
    </Category>
  );
}

export const generalInformationFilters: {
  [key: string]: {
    exists: ValueGetter;
    notEmpty?: any;
    valueGetter: ValueGetter;
  };
} = {
  relavant_information: {
    valueGetter: (auction) => auction?.patient,
    exists: (auction) => auction != null,
  },
  general_information: {
    exists: (auction) => auction != null,
    valueGetter: (auction) => auction,
  },
  insurance: {
    valueGetter: (auction) => ({
      profile: auction?.patient?.profile,
      auction: auction,
    }),
    exists: (auction) => {
      const isInsuranceDefined =
        auction?.patient?.profile?.financing?.insurance?.name;
      const hasInsuranceNumber =
        auction?.patient?.profile?.financing?.insurance_number != null;

      return isInsuranceDefined || hasInsuranceNumber;
    },
  },
  health_insurance: {
    valueGetter: (auction) => ({
      profile: auction?.patient?.profile,
      auction: auction,
    }),
    exists: (auction) =>
      auction?.patient?.profile?.financing?.patient_has_health_insurance_state,
  },
  carelevel: {
    valueGetter: (auction) => ({
      carelevel: auction?.patient?.profile?.financing?.carelevel,
      public_care_insurance_status:
        auction?.patient?.profile?.financing?.public_care_insurance_status,
    }),
    exists: (auction) =>
      auction?.patient?.profile?.financing?.carelevel?.level != null,
  },
  payout: {
    valueGetter: (auction) => auction?.patient?.profile?.financing?.payout,
    exists: (auction) => {
      const payout = auction?.patient?.profile?.financing?.payout;
      return (payout && payout !== 0) === true;
    },
  },
  living_situation: {
    valueGetter: (auction) => ({
      profile: auction?.patient?.profile,
      search_type: auction?.search_type,
    }),
    exists: (auction) =>
      auction?.patient &&
      inputCollectionIsNotEmpty({
        assertionObject: auction.patient.profile,
        keysToExist: [
          "living_situation",
          "lift_available_state",
          "unsafe_current_domestic_situation_state",
          "barrier_free",
        ],
      }),
  },
  general_practitioner: {
    valueGetter: (auction) => auction?.patient?.profile?.general_practitioner,
    exists: (auction) =>
      auction?.patient?.profile?.general_practitioner &&
      !!(
        auction.patient.profile.general_practitioner.name ||
        auction.patient.profile.general_practitioner.contact_details
      ),
  },
  communication: {
    valueGetter: (auction) => auction?.patient?.profile?.communication,
    exists: (auction) =>
      auction?.patient?.profile?.communication &&
      !!(
        auction.patient.profile.communication.has_guardian ||
        auction.patient.profile.communication.guardian_description ||
        auction.patient.profile.communication
          .patient_cannot_communicate_in_german ||
        auction.patient.profile.communication.patient_language ||
        auction.patient.profile.communication.patient_uses_sign_language ||
        auction.patient.profile.communication.relatives_available ||
        auction.patient.profile.communication.patient_is_contact ||
        auction.patient.profile.communication.guardian_requested
      ),
  },
  payers: {
    valueGetter: (auction) => auction?.payers,
    exists: (auction) => {
      return !!(
        auction?.payers &&
        (auction.payers.selected_payment_method ||
          auction.payers.further_information ||
          auction.payers.supplementary_insurance_state ||
          auction.payers.supplementary_payment_state)
      );
    },
  },
  grants: {
    valueGetter: (auction) => auction?.patient?.profile?.financing,
    exists: (auction) =>
      auction?.patient?.profile?.financing &&
      !isEmpty(auction?.patient?.profile?.financing) &&
      auction?.search_type === SEARCH_TYPE_CARE,
  },
  preferences: {
    valueGetter: (auction) => auction?.patient?.profile?.preferences,
    exists: (auction) => {
      if (!auction?.patient?.profile?.preferences) {
        return false;
      }
      const {
        accompanying_person,
        barrier_free_room,
        room_type,
        specialist_doctor,
      } = auction?.patient?.profile.preferences ?? {};
      return (
        ((room_type != 0 && room_type != null) ||
          accompanying_person ||
          barrier_free_room ||
          specialist_doctor) === true
      );
    },
  },
};

function getWhitelist({ formInputValue }: { formInputValue: Auction }) {
  return {
    general_information: true,
    insurance: descriptiveWhitelist(insuranceWhitelist)({
      formInputValue,
    }),
    health_insurance: descriptiveWhitelist(healthInsuranceWhitelist)({
      formInputValue,
    }),
    carelevel: descriptiveWhitelist(carelevelWhitelist)({
      formInputValue,
    }),
    payout: true,
    living_situation: true,
    grants: formInputValue?.assessment_variant !== ASSESSMENT_SLUG_MOBILE_SHORT,
    general_practitioner: true,
    communication: true,
    payers: true,
    preferences: true,
  };
}

const componentMapping = [
  {
    Component: GeneralInformation,
    key: "general_information",
  },
  {
    Component: Insurance,
    key: "insurance",
  },
  {
    Component: HealthInsurance,
    key: "health_insurance",
  },
  {
    Component: Carelevel,
    key: "carelevel",
  },
  {
    Component: Payout,
    key: "payout",
  },
  {
    Component: LivingSituation,
    key: "living_situation",
  },
  {
    Component: GeneralPractitioner,
    key: "general_practitioner",
  },
  {
    Component: Communication,
    key: "communication",
  },
  {
    Component: Grants,
    key: "grants",
  },
  {
    Component: Payers,
    key: "payers",
  },
  {
    Component: Preferences,
    key: "preferences",
  },
];

const getCategories = ({
  auction,
  oldAuction,
}: {
  auction: Auction;
  oldAuction?: Auction;
}) => {
  const whitelist = getWhitelist({
    formInputValue: auction,
  });
  return componentMapping
    .filter((c) => {
      const display = (whitelist as AnyObject)[c.key];

      if (display == null) {
        console.error("Missing whitelist for component", c.key);
        return true;
      }

      return display === true;
    })
    .map((c) => ({
      Component: c.Component,
      key: c.key,
      exists:
        generalInformationFilters[c.key].exists(
          oldAuction ||
            ({
              __typename: "Auction",
              id: -1,
              search_type: -1,
              solutions: [],
              patient: { id: -1 },
            } as unknown as Auction),
        ) || generalInformationFilters[c.key].exists(auction),
      valueGetter: generalInformationFilters[c.key].valueGetter,
    }));
};

export default function InformationCard({
  auction,
  getOntology,
  oldAuction,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
}) {
  const translations = useTranslations();
  const { Card } = usePatientInfoContext();
  const { app } = useEnvContext();
  const isCareproviderApp = app === APP_CAREPROVIDER;
  const title = isCareproviderApp
    ? translations.patient.patientInformation.stepperTitle
    : undefined;
  const categories = getCategories({
    auction,
    oldAuction,
  });

  return (
    <PatientInfoSlugContext.Provider
      value={{
        patientId: auction.patient.id,
        auctionId: auction.id,
        assessmentSlug: ASSESSMENT_SLUG_INFO,
      }}
    >
      <Card title={title}>
        <Categories
          auction={auction}
          oldAuction={oldAuction}
          translations={translations}
          getOntology={getOntology}
          categories={categories}
        />
      </Card>
    </PatientInfoSlugContext.Provider>
  );
}
